import React from "react";
const Code = ({ children }) => {
  return (
    <code
      style={{
        color: "var(--clr-primary)",
        borderRadius: "var(--radius)",
        background: "#a8b0dc1f",
        fontWeight: "500",

        fontFamily: "DM Sans",
        lineHeight: "2rem",
      }}
    >
      {children}
    </code>
  );
};

export default Code;
