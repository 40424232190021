import React from "react";
export const RegVideo = ({ src }) => {
  return (
    <div className="RegVideoWrapper">
      <video controls autoPlay muted loop>
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
};

export const Video = ({ src, title, ...props }) => {
  return (
    <div className="IframeVideoWrapper">
      <div className="video">
        <iframe
          src={src}
          title={title}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </div>
    </div>
  );
};

Video.defaultProps = {
  src: "https://www.youtube.com/embed/-8ORfgUa8ow",
  title: "the best html css tutorial ever !",
};
