import React from "react";

function getAnchor(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/[^a-z0-9 ]/g, "")
    .replace(/[ ]/g, "-");
}

const myH1 = ({ children, title }) => {
  const anchor = getAnchor(children);

  if (title) {
    return (
      <div className="HeadingComponent">
        <h1
          id={anchor}
          className="HeadingH1"
          style={{ color: "var(--clr-grey-3)" }}
        >
          {children}
        </h1>
        <div className="underline"></div>
      </div>
    );
  }
  return (
    <h1
      id={anchor}
      className="HeadingH1"
      style={{
        color: "var(--clr-grey-5)",
      }}
    >
      {children}
    </h1>
  );
};

const myH2 = ({ children, title }) => {
  const anchor = getAnchor(children);
  const link = `#${anchor}`;
  if (title) {
    return (
      <div className="HeadingComponent">
        <h2
          id={anchor}
          className="HeadingH2"
          style={{ color: "var(--clr-primary-5)" }}
        >
          <a href={link} className="anchor-link">
            🔗
          </a>
          {children}
          <div className="underline"></div>
        </h2>
      </div>
    );
  }
  return (
    <h2
      id={anchor}
      className="HeadingH2"
      style={{ color: "var(--clr-primary-5)" }}
    >
      <a href={link} className="customLink anchor-link">
        🔗
      </a>
      {children}
    </h2>
  );
};

const myH3 = ({ children, title }) => {
  const anchor = getAnchor(children);
  if (title) {
    return (
      <div className="HeadingComponent">
        <h3
          id={anchor}
          className="HeadingH3"
          style={{ color: "var(--clr-grey-3)" }}
        >
          {children}
        </h3>
        <div className="underline"></div>
      </div>
    );
  }
  return (
    <h3
      id={anchor}
      className="HeadingH3"
      style={{ color: "var(--clr-grey-3)" }}
    >
      {children}
    </h3>
  );
};

const myH4 = ({ children, title }) => {
  const anchor = getAnchor(children);
  if (title) {
    return (
      <div className="HeadingComponent">
        <h4
          id={anchor}
          className="HeadingH4"
          style={{ color: "var(--clr-grey-3)" }}
        >
          {children}
        </h4>
        <div className="underline"></div>
      </div>
    );
  }
  return (
    <h4
      id={anchor}
      className="HeadingH4"
      style={{ color: "var(--clr-grey-3)" }}
    >
      {children}
    </h4>
  );
};

export { myH1, myH2, myH4, myH3 };
