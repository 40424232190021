import React from "react";

function CustomLinks({ children, href, button }) {
  if (button) {
    return (
      <a
        className="cta cta-action customButton"
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        style={{ padding: "1rem" }}
      >
        {children}
      </a>
    );
  }
  return (
    <a className="customLink" href={href}>
      {children}
    </a>
  );
}

export default CustomLinks;
