import React from "react";
import { FiInfo } from "react-icons/fi";
import { TiWarningOutline } from "react-icons/ti";
import { GoQuote } from "react-icons/go";

const Blockquote = ({ children, display }) => {
  if (display === "warning")
    return (
      <div className="blockquoteContainer warningContainer">
        <div className="warning">
          <TiWarningOutline className="icon" />
          {children}
        </div>
      </div>
    );
  if (display === "info")
    return (
      <div className="blockquoteContainer infoContainer">
        <div className="info">
          <FiInfo className="icon" />
          {children}
        </div>
      </div>
    );
  if (display === "default") {
    return (
      <div className="blockquoteContainer">
        <div className="default">{children}</div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="quote">
          <GoQuote className="quote-icon" />
          {children}
        </div>
      </div>
    );
  }
};

export default Blockquote;
