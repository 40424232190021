import React from "react";
import { MDXProvider } from "@mdx-js/react";
import {
  Headings,
  Blockquote,
  Code,
  PrismSetup,
  CustomLinks,
} from "./src/components/Complete";

const component = {
  h1: Headings.myH1,
  h2: Headings.myH2,
  h3: Headings.myH3,
  h4: Headings.myH4,
  inlineCode: Code,
  blockquote: Blockquote,
  pre: PrismSetup,
  a: CustomLinks,
};

export const wrapMDX = ({ element }) => {
  return <MDXProvider components={component}>{element}</MDXProvider>;
};
